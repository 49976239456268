import React, { useEffect, useState } from "react";
import HeaderComponent from "../HeaderComponent";
import FooterComponent from "../FooterComponent";
import MetaTags from "react-meta-tags";
import StructuredBreadcrumb, { IBreadcrumb } from "../BreadCrumbs";
import { AccordionComponent } from "../ServicesPages/AccordionComponent";
import "./MinibusPage.scss";

export default function MinibusPage() {
    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "Vehicles",
          url: "https://www.simber.co.uk/vehicles",
        },
        {
          description: "Mercedes V-class",
          url: "https://www.simber.co.uk/vehicles/minibus-8-16-seater-hire",
        },
      ];
    
      return (
        <div className="service-background-wrapper">
          <MetaTags>
            <meta
              name="description"
              content="Travel in groups easily. Hire Simber’s minibus chauffeur service, ensuring comfort, convenience, and luxury experience for everyone. Check out now!"
            />
            <title>Simber Minibus Hire for Group Trips</title>
            <link
              rel="canonical"
              href={"https://www.simber.co.uk/vehicles/minibus-8-16-seater-hire"}
            />
          </MetaTags>
          <div className="service-page-wrapper">
            <div className="content-wrapper"></div>
            <HeaderComponent />
            <main>
              <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
              <div className="container">
                <div className="service-picture minibus-8-16-wrapper"></div>
                <img
                  onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                  className="top-img"
                  src={require("../../images/pictograms/top_arrow.png")}
                />
                <div className="content">
                  <h1><span>Group Travel Made Easy With Simber Minibus Hire</span></h1>
                  <p>Travelling in a group? Tired of handling multiple cars or tickets for public transport? It's time to discover Simber's minibus hire for a comfortable, stylish, and stress-free London ride.</p>
                  <p>Whether you're planning a corporate outing, a family vacation, or a night out with friends, our chauffeur-driven minibuses ensure everyone travels together in style.</p>
                  <h2>Why Choose Simber's Minibus Hire Services?</h2>
                  <p>From luxury to spaciousness and convenience, there are so many reasons to choose our chauffeur service with minibus. But here's the breakdown:</p>
                  <ul className="dot-list">
                    <li><strong>Spacious Comfort</strong> - Enter your request so we can estimate if you're in need of a specific number of seats or maybe for more comfort while travelling</li>
                    <li><strong>Licensed Professionals</strong> - The drivers are trained, certified, and experienced in providing top-notch luxury minibus rides so you can relax and enjoy the trip</li>
                    <li><strong>Exceptional Convenience</strong> - No matter how large your group is, your minibus will be the closest possible to your door and drop you off the closest possible to the wanted spot</li>
                    <li><strong>Luxury Touches</strong> - Our minibus hire means an option to control the air conditioning and take advantage of the playlists while travelling</li>
                    <li><strong>Fair Pricing</strong> - Since it's a group travel, the competitive prices can surely adjust the expense you could have with public transport or any other method</li>
                  </ul>
                  <p>But if we have to choose two reasons to hire a minibus with a driver, those would be convenience and professionalism!</p>
                  <h2>What do We Offer?</h2>
                  <p>For medium-sized groups, the 12-seater minibus hire can be the ideal option as it offers enough space for the luggage. We cater to groups of all sizes, whether is a group of 8 or 16 people - but you have to specify the number in the inquiry!</p>
                  <p>Here are more of our services:</p>
                  <ul className="dot-list">
                    <li><strong>Minibus Hire in London</strong> - Explore London with your group and never miss an attractive spot because of the transportation</li>
                    <li><strong>Luxury Minibus Hire</strong> - For groups that want to make a statement on special occasions like weddings or corporate events</li>
                    <li><strong>Executive Minibus Hire</strong> - This is sophisticated way to feel special and receive VIP transportation even in a group</li>
                  </ul>
                  <p>Ready to experience the comfort and convenience of Simber Minibus Hire? Contact us today for a free quote, and let us take the stress out of your group travel.</p>
                  <p>
                    <button
                      className="primary-button center"
                      onClick={() =>
                        (window.location.href = "https://www.simber.co.uk/contact")
                      }
                    >
                      Contact us
                    </button>
                  </p>
                  <div className="devider"></div>
                  <h2 className="faq-section-header">FAQs</h2>
                  <div
                    className="faq-wrapper"
                    itemScope
                    itemType="https://schema.org/FAQPage"
                  >
                    <AccordionComponent
                      question="Can smaller groups hire larger minibuses?"
                      answer="You can consider our minibus like 9-seater minibus hire, or even 16-seater minibus hire, but it's important to know that we welcome groups of all sizes. The price depends on the vehicle's size, so we charge based on the available capacity."
                    />
                    <AccordionComponent
                      question="How much does the minibus hire cost?"
                      answer="There is no fixed rate, since many factors can add to the final price. In general, it depends on the duration of the trip, the distance, and all the services in the meantime. It's best to contact us for a quote."
                    />
                    <AccordionComponent
                      question="Do your minibuses have wheelchair access?"
                      answer="While not every vehicle has a built-in option for this request, it's best to note that in your inquiry so we can accommodate all your needs with a mobile ramp."
                    />
                    <AccordionComponent
                      question="Can we request a specific pick-up and drop-off location?"
                      answer="Yes, we offer door-to-door service for your convenience. Please specify your desired locations during your booking."
                    />
                    <AccordionComponent
                      question="How far in advance do I need to book a minibus?"
                      answer="While we try our best to accommodate last-minute requests, advanced booking is recommended, especially during peak seasons."
                    />
                  </div>
                </div>
              </div>
            </main>
            <FooterComponent />
          </div>
        </div>
      );
}