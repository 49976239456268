import React, { useEffect, useState } from "react";
import HeaderComponent from "../HeaderComponent";
import FooterComponent from "../FooterComponent";
import MetaTags from "react-meta-tags";
import StructuredBreadcrumb, { IBreadcrumb } from "../BreadCrumbs";
import "../BlogDetailPage.scss";
import { AccordionComponent } from "../ServicesPages/AccordionComponent";
import SearchComponent from "../SearchComponent";
import "./BmwI7Page.scss";

export default function BmwI7Page() {

  const [bookingOpen, setBookingOpen] = useState(false);

  const breadcrumbList: IBreadcrumb[] = [
    {
      description: "Vehicles",
      url: "https://www.simber.co.uk/vehicles",
    },
    {
      description: "BMW I7",
      url: "https://www.simber.co.uk/vehicles/bmw-i7-car-hire",
    },
  ];

  return (
    <div className="service-background-wrapper">
      <MetaTags>
        <meta name="description" content="Electric vehicle chauffeur? Say no more. Learn why hiring a BMW i7 from our vehicle fleet is the best choice for your extended stay in London and the UK." />
        <title>BMW i7 Chauffeur Hire | London & UK</title>
        <link
          rel="canonical"
          href={"https://www.simber.co.uk/vehicles/bmw-i7-car-hire"}
        />
      </MetaTags>
      <div className="service-page-wrapper">
        <div className="content-wrapper"></div>
        <HeaderComponent />
        <main>
          <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
          <div className="container">
            <img
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="top-img"
              src={require("../../images/pictograms/top_arrow.png")}
            />
            <div className="i7-wrapper service-picture"></div>
            <div className="content">
              <h1 ><span>Hire a BMW i7 Chauffeur Service in London</span></h1>
              <p>For a truly unforgettable journey in London or around the UK, choose the sleek and sophisticated BMW i7 with chauffeur service. Perfect for business meetings, grand entrances, or simply arriving in unparalleled style.</p>
              <p>Simber's BMW i7 makes every ride next-level, thanks to its electric engine. With a luxurious interior, it provides both productivity and relaxation - a perfect way to start your London & UK adventure.</p>
              <h2>Luxury Travel Experience with BMW i7</h2>
              <p>Discover the ease that electric vehicles provide with our BMW i7 chauffeur service. With a sleek design and exceptional performance capabilities, this vehicle is clients' top choice.</p>
              <p>What do you get?</p>
              <ul className="dot-list">
                <li><strong>Elegance</strong> - Beautiful design, elegant appearance, and extraordinary interior</li>
                <li><strong>Comfort</strong> - A spacious cabin and cushioned seats that follow a person's anatomy</li>
                <li><strong>Technology</strong> - Quiet electric motor that offers a calm ride</li>
              </ul>
              <p>Whether it's a <a href="https://www.simber.co.uk/services/corporate-chauffeur">business meeting</a>, a grand entrance, or simply a luxurious escape, the BMW i7 chauffeur service guarantees you arrive refreshed and prepared to face any daily challenge.</p>
              <h2>Why a BMW Car Hire?</h2>
              <p>BMW i7 redefines everything we know about chauffeur services and luxury travel. This revolutionary electric sedan gives a whole new light to transportation services, offering an unparalleled experience that goes beyond simply getting you from point A to point B.</p>
              <p>Here's why the BMW i7 is the perfect choice for your next journey:</p>
              <ul className="dot-list">
                <li><strong>Effortless Efficiency</strong> - Traffic is no problem for our BMW i7, thanks to its powerful electric powertrain. Sit back, relax, and let your professional chauffeur handle the trip with ease, ensuring a stress-free and eco-conscious experience.</li>
                <li><strong>Performance and Comfort</strong> - The instant electric torque combined with the spacious interior make your chauffeured journey a premium experience.</li>
                <li><strong>Arrive Sharper and More Connected</strong> - The BMW i7 chauffeur service guarantees you arrive at your destination refreshed and ready to tackle anything.</li>
              </ul>
              <p>The BMW car hire will elevate your <a href="https://www.simber.co.uk/services/private-tours">private transportation</a> expectations to a new level. It's an investment in a future-forward experience with comfort, style, and elegance as top values.</p>
              <h3>Silent Speed and VIP Treatment</h3>
              <p>Your i7 BMW chauffeur service means you'll travel in calm and silence all the time. Embrace its quiet motor and cutting-edge technology to have a stress-free London trip.</p>
              <p>What else do you get?</p>
              <ul className="dot-list">
                <li>Efficient ride through traffic</li>
                <li>Professional chauffeur to navigate the ride</li>
                <li>Eco-conscious travel</li>
                <li>Arriving feeling like a VIP</li>
              </ul>
              <p>With its efficiency in travelling, our BMW i7 is the perfect choice for everyone, no matter if they are in a hurry or just need a relaxed ride through London's streets.</p>
              <h3>Work or Recharge, It's Your Choice</h3>
              <p>Your i7 BMW car hire is an investment in your comfort to choose what to do while travelling. Hurrying to a meeting? No problem, check on the emails one last time. Relaxing after an event? Just sink into the comfortable seats while the driver takes you anywhere you need to be next.</p>
              <p>But how's that even possible with the BMW i7?</p>
              <ul className="dot-list">
                <li>Spacious interior</li>
                <li>Comfortable seats</li>
                <li>A calm environment to relax</li>
              </ul>
              <p>Choose the BMW i7 chauffeur hire and experience the convenience everyone is talking about.</p>
              <h2>Book Your BMW i7 Chauffeur Service in London & UK</h2>
              <p>Experience the comfort of stress-free travelling by hiring a BMW i7 chauffeur service. Never choose the ordinary - always go for extraordinary. </p>
              <p>Pro Tip: Experience a concert on wheels with the i7's Bowers & Wilkins sound system and rear seat theater with built-in Fire TV.</p>
              <p>Are you ready for this journey?</p>
              <p><button className="primary-button center" onClick={() => setBookingOpen(!bookingOpen)}>Book Now</button></p>
              <div className="devider"></div>
            <h2 className="faq-section-header">FAQs</h2>                    
            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                <AccordionComponent
                    question="What services are included in my BMW i7 chauffeur service booking?" 
                    answer="Our BMW i7 chauffeur service typically includes professional driver, meet & greet, bottled water, and waiting time (within reason). Inquire for full details."/>
                <AccordionComponent
                    question="How many passengers can comfortably fit in the BMW i7?" 
                    answer="The BMW i7 comfortably seats 4 passengers."/>
                <AccordionComponent
                    question="Are the BMW i7s maintained to ensure safety and comfort?" 
                    answer="Absolutely! Our BMW i7s undergo regular maintenance to ensure your safety and comfort."/>
            </div>
            </div>
          </div>
          <SearchComponent isOpen={bookingOpen} close={() => setBookingOpen(false)}/>
        </main>
        <FooterComponent/>
      </div>
    </div>
  );
}
