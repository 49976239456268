import React, { useState, useEffect, useRef } from 'react'
import { Button, Dialog, DialogContent, Menu, MenuItem } from '@material-ui/core'
import { connect } from 'react-redux'
import "./HeaderComponent.scss"
import Navbar from "react-bootstrap/Navbar";
import { RootState } from '../redux/reducers/rootReducer';
import { Redirect } from 'react-router';
import { UserActionTypes, SummaryActionTypes } from '../redux/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { clearState, clearUserState } from "../redux/actions"
import { redirectToFb, redirectToInsta } from "../config/Contacts"
import { axiosInstance } from '../config/Axios.config';
import { useIdleTimer } from 'react-idle-timer'
import NestedMenuItem from 'material-ui-nested-menu-item';
import Modal from '@mui/material/Modal';
import GalleryPage from './GalleryPage';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

interface OwnProps {
    initial?: number;
    resetInitial?(): void
}

interface IMappedProps {
    email: string;
    name: string;
    token: string;
}

interface IDispatchedProps {
    clearState: () => void;
    clearUserState: () => void;
}

type Props = IMappedProps & IDispatchedProps & OwnProps;

function HeaderComponent(props: Props) {
    const [redirect,setRedirect] = useState(false);
    const [homeRedirect,setHomeRedirect] = useState(false);
    const [blogRedirect,setBlogRedirect] = useState(false);
    const [init,setInit] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [profileRedirect,setProfileRedirect] = useState("");
    const [login,setLogin] = useState(false);
    const [vehiclesRedirect,setVehiclesRedirect] = useState(false);
    const [galleryRedirect, setGalleryRedirect] = useState(false);
    const [galleryInitial,setGalleryInitial] = useState<number>();
    const [exiting, setExiting] = useState(false);
    const [accountRedirect,setAccountRedirect] = useState(false);
    const [scroll, setScroll] = useState("");
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false);
    const active = document.URL.split("/").pop();
    const [expanded, setExpanded] = useState(false);
    const [headerHeight, setHeaderHeight] = useState('auto');

    useEffect(() => {
        if(expanded) {
            document.body.style.height = '100dvh';
            setHeaderHeight('100dvh');
            document.body.style.overflowY = 'hidden';
        }
        else {
            document.body.style.height = '';
            setHeaderHeight('auto');
            document.body.style.overflowY = 'unset';
        }
    },[expanded])

    const handleOnIdle = (event: any) => {
        if(props.token)
            onLogoutClick();
    }
    
    const handleOnActive = (event: any) => {
    }
    
    const handleOnAction = (event: any) => {
    }
    
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * + Number("15")!,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
    })

    const refEl = useRef(null);
    const refElCorp = useRef(null);
    const vehiclesMenuRef = useRef<HTMLDivElement>(null);

    const setSubmenuEffects = (ev?:MouseEvent, timeout = 300) => {
        setTimeout(
            () => {
                if(!expanded && !refEl.current && !refElCorp.current) {
                    setAnchorEl(null);
                }
            }, timeout);
    }

    const setSubmenuEffectsVehicles = (ev?:MouseEvent, timeout = 300) => {
        setTimeout(
            () => {
                if(!expanded) {
                    const eleBounds = vehiclesMenuRef.current?.getBoundingClientRect();
                    if (ev && eleBounds && ev.clientX >= eleBounds.left && ev.clientX <= eleBounds.right
                        && (ev!.clientY+10) >= eleBounds.top  && ev!.clientY <= eleBounds.bottom
                    ) {

                    } else {
                        setVehiclesAnchorEl(null);
                    }
                }
            }, timeout);
    }

    const handleScroll = () => {
        setSubmenuEffects(undefined, 1);
        setSubmenuEffectsVehicles(undefined, 1);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, false);
        setLoaded(true);
        return function cleanup() {
            document.removeEventListener('scroll', handleScroll, false)
        }
    }, []);

    useEffect(() => {
        if(props.initial && !galleryRedirect) {
            setGalleryRedirect(true);
            setGalleryInitial(props.initial);
            if(props.resetInitial)props.resetInitial();
        }
        if(!loaded) {
            setLoaded(true);
        }
        if(init && loaded){
            var menu = document.getElementById("services-menu");
            var div = menu?.children[2];
            (div as HTMLElement)!.onmouseleave = setSubmenuEffects
            
            menu = document.getElementById("services-menu-vehicles");
            div = menu?.children[2];
            (div as HTMLElement)!.onmouseleave = setSubmenuEffectsVehicles
            setInit(false);
        }
        if(blogRedirect) {
            setBlogRedirect(false);
        }
        if(redirect) {
            setRedirect(false);
            setScroll("");
            props.clearState();
            props.clearUserState();
        }
        if(homeRedirect) {
            setHomeRedirect(false);
        }
        if(profileRedirect !== "") {
            setProfileRedirect("")
            setAccountMenuOpen(false);
        }
        if(login)
            setLogin(false);
        if(vehiclesRedirect)
            setVehiclesRedirect(false);
        if(scroll !== "") {
            setScroll("");
        }
        if(accountRedirect) {
            setAccountRedirect(false);
        }
    })

    useEffect(()=>{
        if(galleryRedirect) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = "100dvh";
        }
        else {
            document.body.style.overflow = 'auto';
            document.body.style.height = "";
        }
    }, [galleryRedirect])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [vehiclesAnchorEl, setVehiclesAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(Boolean(anchorEl)){
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }        
    };

    const handleVehiclesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(Boolean(vehiclesAnchorEl)){
            setVehiclesAnchorEl(null);
        } else {
            setVehiclesAnchorEl(event.currentTarget);
        }        
    };

    const onLogoutClick = () => {
        axiosInstance.defaults.headers["Authorization"] = "Bearer " + props.token;
        axiosInstance.get("user/logout")
            .then(res => {

            })
            .catch(err => {})
        var c = localStorage.getItem("cookie");
        localStorage.clear();
        if(c)
            localStorage.setItem("cookie","true")
        setLogoutConfirmOpen(false);
        setRedirect(true);
    };
    
    if(redirect) {                      
        return (<Redirect push to={{pathname:"/home", state:{}}} />)
    }
    else
    if(homeRedirect) {
        return (<Redirect push to={{pathname:"/home", state:{}}} />)
    }
    else
    if(blogRedirect) {
        return (<Redirect push to={{pathname:"/blog", state:{}}} />)
    }
    else
    if(profileRedirect !== "") {
        return (<Redirect push to={{pathname: "/profile/" + profileRedirect, state:{}}} />)
    }
    else
    if(scroll !== "") {                      
        return (<Redirect push to={{pathname:"/" + scroll,state:{}}}  />)
    }
    else
    if(login)
        return (<Redirect push to="/login" />)
    else
    if(vehiclesRedirect)
        return (<Redirect push to="/vehicles" />)
    else
    // if(galleryRedirect)
    //     return (<Redirect push to="/gallery" />)
    // else
    if(accountRedirect)
        return (<Redirect push to="/account" />)
    else {
        return (
            <header className={expanded ? "header-scroll" : ""} style={{height: headerHeight}}>
                <Modal
                    open={galleryRedirect}>
                        <Fade in={galleryRedirect} timeout={500}>
                            <Box>
                                <GalleryPage initial={galleryInitial} onHide={() => { setGalleryRedirect(false); }}/>
                            </Box>
                        </Fade>
                </Modal>
                <Navbar 
                    variant="light"
                    expand="lg"
                    id="header-container"
                    className={expanded ? "header-container open" : "header-container"}
                    expanded={expanded}
                    onToggle={(e) => {
                        window.scroll({top: 0});
                        setExpanded(!expanded);
                    }}>
                    { expanded && <img className="header-strip" src={require("../images/rectangle_white.png")} />}
                    <img
                        onClick={() => setHomeRedirect(true)}
                        alt="Logo of Simber, black and golden reading 'Simber Chauffeur Services'."
                        className="header-img-collapsed"
                        src={require("../images/white_logo.png")}
                    ></img>
                    <Navbar.Toggle />
                    <div className="start">
                        <img
                            onClick={redirectToFb}
                            alt="Pictogram of Facebook."
                            src={require("../images/pictograms/fb_black.png")}
                        ></img>
                        <img
                            onClick={redirectToInsta}
                            alt="Pictogram of Instagram."
                            src={require("../images/pictograms/insta_black.png")}
                        ></img>
                    </div>
                    <Navbar.Collapse className="menu" >
                        <div className='left-section'>
                            <Button onClick={() => setScroll("about")} className={active === "about" ? "active" : ""}>
                                <a className="link" href="https://www.simber.co.uk/about" >About Us</a>
                            </Button>
                            <Button 
                                className={
                                    Boolean(anchorEl) 
                                    ? (
                                        (active ==="airport-transfers" || active ==="long-term-chauffeur" || active ==="long-distance-chauffeur" || active ==="corporate-chauffeur" || active ==="executive-transfers" || active ==="events-chauffeurs" || active ==="wedding-chauffeur" || active ==="private-tours" || active ==="seaport-transfers" || active === "heathrow" || active === "gatwick" ) 
                                            ? "services-menu-button open active" 
                                            :"services-menu-button open"
                                    ) 
                                    : (
                                        (active ==="airport-transfers" || active ==="long-term-chauffeur" || active ==="long-distance-chauffeur" || active ==="corporate-chauffeur" || active ==="executive-transfers" || active ==="events-chauffeurs" || active ==="wedding-chauffeur" || active ==="private-tours" || active ==="seaport-transfers" || active === "heathrow" || active === "gatwick") 
                                            ? "services-menu-button active" 
                                            : "services-menu-button"
                                    )
                                }
                                aria-controls="services-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                onMouseOver={(event) =>{if(window.innerWidth > 991) setAnchorEl(event.currentTarget)}}
                                onMouseLeave={(e) => setSubmenuEffects(e.nativeEvent)}
                            >
                                <span>Services</span>
                                <div className='hide'>
                                    <a className="link" href="https://www.simber.co.uk/services/airport-transfers" ><span className="menu-item-text">Airport Transfers</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/airport-transfers/heathrow" ><span className="menu-item-text">Luxury Airport Transfer to Heathrow</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/airport-transfers/gatwick" ><span className="menu-item-text">Luxury Airport Transfer to Gatwick</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/long-term-chauffeur" ><span className="menu-item-text">Long Term Chauffeuring</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/long-distance-chauffeur" ><span className="menu-item-text">Long Distance Chauffeuring</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/corporate-chauffeur" ><span className="menu-item-text">Corporate Chauffeur</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/executive-transfers" ><span className="menu-item-text">Executive Chauffeur</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/events-chauffeurs" ><span className="menu-item-text">Event Chauffeuring</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/wedding-chauffeur" ><span className="menu-item-text">Weddings</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/private-tours" ><span className="menu-item-text">Private Tours</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/seaport-transfers" ><span className="menu-item-text">Cruise Port Transfers</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" ><span className="menu-item-text">Strategic Partnership</span></a>
                                    <a className="link" href="https://www.simber.co.uk/services/luxury-minibus-hire" ><span className="menu-item-text">Luxury Minibus Hire</span></a>
                                </div>
                                <img src={require("../images/pictograms/arrow_brown.png")} />
                            </Button>
                            <Menu
                                id="services-menu"
                                disableScrollLock
                                anchorEl={anchorEl}
                                keepMounted={true}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                                getContentAnchorEl={null}
                                className="service-menu"
                                open={Boolean(anchorEl)}
                                onClose={() => {setScroll(""); setAnchorEl(null);}}>
                                    <NestedMenuItem
                                        label={<a className="link" href="https://www.simber.co.uk/services/airport-transfers" ><span className="menu-item-text">Airport Transfers</span></a>} focusVisibleClassName={'focus'} parentMenuOpen={Boolean(anchorEl)} className={active === "airport-transfers" || active === "heathrow" || active === "gatwick" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers")}}>
                                        <div
                                        ref={refEl}
                                        onMouseLeave={(e) => setSubmenuEffects(e.nativeEvent)}>
                                            <MenuItem className={active === "heathrow" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers/heathrow")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers/heathrow" ><span className="menu-item-text">Luxury Airport Transfer to Heathrow</span></a></MenuItem>
                                            <MenuItem className={active === "gatwick" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers/gatwick")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers/gatwick" ><span className="menu-item-text">Luxury Airport Transfer to Gatwick</span></a></MenuItem>
                                        </div>
                                    </NestedMenuItem>
                                    <MenuItem className={active === "long-term-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/long-term-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/long-term-chauffeur" ><span className="menu-item-text">Long Term Chauffeuring</span></a></MenuItem>
                                    <MenuItem className={active === "long-distance-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/long-distance-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/long-distance-chauffeur" ><span className="menu-item-text">Long Distance Chauffeuring</span></a></MenuItem>
                                    <NestedMenuItem
                                        label={<a className="link"><span className="menu-item-text">Corporate Chauffeur</span></a>} focusVisibleClassName={'focus'} parentMenuOpen={Boolean(anchorEl)} className={active === "corporate-chauffeur" || active === "executive-transfers" ? "active" : ""}>
                                        <div
                                        ref={refElCorp}
                                        onMouseLeave={(e) => setSubmenuEffects(e.nativeEvent)}>
                                            <MenuItem className={active === "corporate-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/corporate-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/corporate-chauffeur" ><span className="menu-item-text">Corporate Chauffeur</span></a></MenuItem>
                                            <MenuItem className={active === "executive-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/executive-transfers")}}><a className="link" href="https://www.simber.co.uk/services/executive-transfers" ><span className="menu-item-text">Executive Chauffeur</span></a></MenuItem>
                                        </div>
                                    </NestedMenuItem>
                                    <MenuItem className={active === "events-chauffeurs" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/events-chauffeurs")}}><a className="link" href="https://www.simber.co.uk/services/events-chauffeurs" ><span className="menu-item-text">Event Chauffeuring</span></a></MenuItem>
                                    <MenuItem className={active === "wedding-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/wedding-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/wedding-chauffeur" ><span className="menu-item-text">Weddings</span></a></MenuItem>
                                    <MenuItem className={active === "private-tours" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/private-tours")}}><a className="link" href="https://www.simber.co.uk/services/private-tours" ><span className="menu-item-text">Private Tours</span></a></MenuItem>
                                    <MenuItem className={active === "seaport-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/seaport-transfers")}}><a className="link" href="https://www.simber.co.uk/services/seaport-transfers" ><span className="menu-item-text">Cruise Port Transfers</span></a></MenuItem>
                                    <MenuItem className={active === "strategic-partnerships-hotel-transport" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/strategic-partnerships-hotel-transport")}}><a className="link" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" ><span className="menu-item-text">Strategic Partnership</span></a></MenuItem>
                                    <MenuItem className={active === "luxury-minibus-hire" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/luxury-minibus-hire")}}><a className="link" href="https://www.simber.co.uk/services/luxury-minibus-hire" ><span className="menu-item-text">Luxury Minibus Hire</span></a></MenuItem>
                            </Menu>
                            {Boolean(anchorEl) &&
                                <div className="service-menu-mobil">
                                    <MenuItem className={active === "airport-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers" ><span className="menu-item-text">Airport Transfers</span></a></MenuItem>
                                    <div className='small-devider'></div>
                                    <MenuItem className={active === "heathrow" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers/heathrow")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers/heathrow" ><span className="menu-item-text">Luxury Airport Transfer to Heathrow</span></a></MenuItem>
                                    <MenuItem className={active === "gatwick" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers/gatwick")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers/gatwick" ><span className="menu-item-text">Luxury Airport Transfer to Gatwick</span></a></MenuItem>
                                    <div className='small-devider'></div>
                                    <MenuItem className={active === "long-term-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/long-term-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/long-term-chauffeur" ><span className="menu-item-text">Long Term Chauffeuring</span></a></MenuItem>
                                    <MenuItem className={active === "long-distance-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/long-distance-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/long-distance-chauffeur" ><span className="menu-item-text">Long Distance Chauffeuring</span></a></MenuItem>
                                    <MenuItem className={active === "corporate-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/corporate-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/corporate-chauffeur" ><span className="menu-item-text">Corporate Chauffeur</span></a></MenuItem>
                                    <MenuItem className={active === "executive-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/executive-transfers")}}><a className="link" href="https://www.simber.co.uk/services/executive-transfers" ><span className="menu-item-text">Executive Chauffeur</span></a></MenuItem>
                                    <MenuItem className={active === "events-chauffeurs" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/events-chauffeurs")}}><a className="link" href="https://www.simber.co.uk/services/events-chauffeurs" ><span className="menu-item-text">Event Chauffeuring</span></a></MenuItem>
                                    <MenuItem className={active === "wedding-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/wedding-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/wedding-chauffeur" ><span className="menu-item-text">Weddings</span></a></MenuItem>
                                    <MenuItem className={active === "private-tours" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/private-tours")}}><a className="link" href="https://www.simber.co.uk/services/private-tours" ><span className="menu-item-text">Private Tours</span></a></MenuItem>
                                    <MenuItem className={active === "seaport-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/seaport-transfers")}}><a className="link" href="https://www.simber.co.uk/services/seaport-transfers" ><span className="menu-item-text">Cruise Port Transfers</span></a></MenuItem>
                                    <MenuItem className={active === "strategic-partnerships-hotel-transport" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/strategic-partnerships-hotel-transport")}}><a className="link" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" ><span className="menu-item-text">Strategic Partnership</span></a></MenuItem>
                                    <MenuItem className={active === "luxury-minibus-hire" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/luxury-minibus-hire")}}><a className="link" href="https://www.simber.co.uk/services/luxury-minibus-hire" ><span className="menu-item-text">Luxury Minibus Hire</span></a></MenuItem>
                                </div>
                            }
                            
                            <Button
                                className={
                                    Boolean(vehiclesAnchorEl) 
                                    ? (
                                        (active === "mercedes-s-class-chauffeur-hire" || active === "mercedes-v-class-chauffeur-hire" || active === "bmw-i7-car-hire") 
                                            ? "services-menu-button open active" 
                                            :"services-menu-button open"
                                    ) 
                                    : (
                                        (active === "mercedes-s-class-chauffeur-hire" || active === "mercedes-v-class-chauffeur-hire" || active === "bmw-i7-car-hire") 
                                            ? "services-menu-button active" 
                                            : "services-menu-button"
                                    )
                                }
                                aria-controls="services-menu-vehicles"
                                aria-haspopup="true"
                                onClick={handleVehiclesClick}
                                onMouseOver={(event) =>{if(window.innerWidth > 991) setVehiclesAnchorEl(event.currentTarget)}}
                                onMouseLeave={(e) => setSubmenuEffectsVehicles(e.nativeEvent)}>
                                <span>Vehicles</span>
                                <div className='hide'>
                                    <a className="link" href="https://www.simber.co.uk/vehicles/mercedes-s-class-chauffeur-hire" ><span className="menu-item-text">Mercedes S Class</span></a>
                                    <a className="link" href="https://www.simber.co.uk/vehicles/mercedes-v-class-chauffeur-hire" ><span className="menu-item-text">Mercedes V Class</span></a>
                                    <a className="link" href="https://www.simber.co.uk/vehicles/bmw-i7-car-hire" ><span className="menu-item-text">BMW 7 Series</span></a>
                                    <a className="link" href="https://www.simber.co.uk/vehicles/mercedes-senzati-jet-class-travel" ><span className="menu-item-text">Senzati Jet Class</span></a>
                                    <a className="link" href="https://www.simber.co.uk/vehicles/minibus-8-16-seater-hire" ><span className="menu-item-text">Luxury Minibus</span></a>
                                    <a className="link" href="https://www.simber.co.uk/vehicles/rolls-royce-luxury-car-hire" ><span className="menu-item-text">Rolls Royce Ghost</span></a>
                                </div>
                                <img src={require("../images/pictograms/arrow_brown.png")} />
                            </Button>
                            <Menu
                                id="services-menu-vehicles"
                                disableScrollLock
                                anchorEl={vehiclesAnchorEl}
                                keepMounted={true}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                                getContentAnchorEl={null}
                                className="service-menu"
                                open={Boolean(vehiclesAnchorEl)}
                                onClose={() => {setScroll(""); setVehiclesAnchorEl(null);}}>
                                    <div ref={vehiclesMenuRef}>
                                    <MenuItem className={active === "vehicles" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles")}}><a className="link" href="https://www.simber.co.uk/vehicles" ><span className="menu-item-text">Vehicles</span></a></MenuItem>
                                    <MenuItem className={active === "bmw-i7-car-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/bmw-i7-car-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/bmw-i7-car-hire" ><span className="menu-item-text">BMW 7 Series</span></a></MenuItem>
                                    <MenuItem className={active === "mercedes-s-class-chauffeur-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/mercedes-s-class-chauffeur-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/mercedes-s-class-chauffeur-hire" ><span className="menu-item-text">Mercedes S Class</span></a></MenuItem>
                                    <MenuItem className={active === "mercedes-v-class-chauffeur-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/mercedes-v-class-chauffeur-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/mercedes-v-class-chauffeur-hire" ><span className="menu-item-text">Mercedes V Class</span></a></MenuItem>
                                    <MenuItem className={active === "mercedes-senzati-jet-class-travel" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/mercedes-senzati-jet-class-travel")}}><a className="link" href="https://www.simber.co.uk/vehicles/mercedes-senzati-jet-class-travel" ><span className="menu-item-text">Senzati Jet Class</span></a></MenuItem>
                                    <MenuItem className={active === "minibus-8-16-seater-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/minibus-8-16-seater-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/minibus-8-16-seater-hire" ><span className="menu-item-text">Luxury Minibus</span></a></MenuItem>
                                    <MenuItem className={active === "rolls-royce-luxury-car-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/rolls-royce-luxury-car-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/rolls-royce-luxury-car-hire" ><span className="menu-item-text">Rolls Royce Ghost</span></a></MenuItem>
                                    </div>
                            </Menu>
                            {Boolean(vehiclesAnchorEl) &&
                                <div className="service-menu-mobil">
                                    <MenuItem className={active === "vehicles" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles")}}><a className="link" href="https://www.simber.co.uk/vehicles" ><span className="menu-item-text">Vehicles</span></a></MenuItem>
                                    <MenuItem className={active === "bmw-i7-car-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/bmw-i7-car-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/bmw-i7-car-hire" ><span className="menu-item-text">BMW 7 Series</span></a></MenuItem>
                                    <MenuItem className={active === "mercedes-s-class-chauffeur-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/mercedes-s-class-chauffeur-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/mercedes-s-class-chauffeur-hire" ><span className="menu-item-text">Mercedes S Class</span></a></MenuItem>
                                    <MenuItem className={active === "mercedes-v-class-chauffeur-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/mercedes-v-class-chauffeur-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/mercedes-v-class-chauffeur-hire" ><span className="menu-item-text">Mercedes V Class</span></a></MenuItem>
                                    <MenuItem className={active === "mercedes-senzati-jet-class-travel" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/mercedes-senzati-jet-class-travel")}}><a className="link" href="https://www.simber.co.uk/vehicles/mercedes-senzati-jet-class-travel" ><span className="menu-item-text">Senzati Jet Class</span></a></MenuItem>
                                    <MenuItem className={active === "minibus-8-16-seater-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/minibus-8-16-seater-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/minibus-8-16-seater-hire" ><span className="menu-item-text">Luxury Minibus</span></a></MenuItem>
                                    <MenuItem className={active === "rolls-royce-luxury-car-hire" ? "active" : ""} onClick={() => {setVehiclesAnchorEl(null); setScroll("vehicles/rolls-royce-luxury-car-hire")}}><a className="link" href="https://www.simber.co.uk/vehicles/rolls-royce-luxury-car-hire" ><span className="menu-item-text">Rolls Royce Ghost</span></a></MenuItem>
                                </div>
                            }
                        </div>
                        <div className='image-placeholder'></div>
                        <img
                        onClick={() => setHomeRedirect(true)}
                        className="header-img"
                        alt="Logo of Simber, black and golden reading 'Simber Chauffeur Services'."
                        src={require("../images/white_logo.png")}
                        ></img>
                        <div className='right-section'>
                            <Button onClick={() => setBlogRedirect(true)} className={active === "blog" ? "active" : ""}>
                                <a className="link" href="https://www.simber.co.uk/blog" >Blog</a>
                            </Button>
                            
                            <Button onClick={() => setGalleryRedirect(true)} className={active === "gallery" ? "active" : ""}>
                                Gallery{/* <a className="link" href="https://www.simber.co.uk/gallery" >Gallery</a> */}
                            </Button>
                            
                            <Button onClick={() => setScroll("contact")}  className={active === "contact" ? "active" : ""}>
                                <a className="link" href="https://www.simber.co.uk/contact" >Contact Us</a>
                            </Button>
                            {props.token === "" ?
                            (
                                <Button onClick={() => setLogin(true)} className={(active === "login" || active === "register") ? "active login" : "login"}>
                                    <a className="link" href="https://www.simber.co.uk/login" >Sign In/ Sign Up</a>
                                </Button>
                            ) :
                            (
                                <div className="logout desktop">
                                    <div className="closed" onClick={() => setAccountMenuOpen(!accountMenuOpen)}>
                                        <span>{props.name}</span>
                                        <img src={require("../images/pictograms/arrow_orange.png")} />
                                    </div>                            
                                    {accountMenuOpen &&
                                        <div className="account">
                                            <span className={active === "data" ? "active" : ""} onClick={() => setProfileRedirect("data")}><a className="link" href="https://www.simber.co.uk/profile/data" >Profile</a></span>
                                            <span className={active === "bookings" ? "active" : ""} onClick={() => setProfileRedirect("bookings")}><a className="link" href="https://www.simber.co.uk/profile/bookings" >My Bookings</a></span>
                                            <span className={active === "payment" ? "active" : ""} onClick={() => setProfileRedirect("payment")}><a className="link" href="https://www.simber.co.uk/profile/payment" >Payment Method</a></span>
                                            <span className={active === "settings" ? "active" : ""} onClick={() => setProfileRedirect("settings")}><a className="link" href="https://www.simber.co.uk/profile/settings" >Settings</a></span>
                                            <span onClick={() => setLogoutConfirmOpen(true)}>Log Out</span>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                        </Navbar.Collapse>
                    { expanded ||
                        <div className="login-mobil">
                        {props.token &&
                            <div className="logout">
                                <div className="closed" onClick={() => setAccountMenuOpen(!accountMenuOpen)}>
                                    <span>{props.name}</span>
                                    <img src={require("../images/pictograms/arrow_orange.png")} />
                                </div>                            
                                {accountMenuOpen &&
                                    <div className="account">
                                        <span className={(active === "data"|| active === "bookings" || active === "payment" || active === "settings") ? "active" : ""} onClick={() => setProfileRedirect("data")}>Profile</span>
                                        <span onClick={() => setLogoutConfirmOpen(true)}>Log Out</span>
                                    </div>
                                }
                            </div>
                        }
                        </div>
                    }
                    <Dialog
                        className="logout-modal"
                        open={logoutConfirmOpen}
                    >
                        <DialogContent className="logout-dialog-content">
                            <span>Are you sure you want to logout</span>
                            <span className="name">{props.name}?</span>
                            <button className="primary-button logout-button" onClick={() => onLogoutClick()}>Logout</button>
                        </DialogContent>
                        <img alt="Pictogram showing an 'X' representing the menu closing button." className="close-button" src={require("../images/pictograms/close_pictogram.png")} onClick={() => setLogoutConfirmOpen(false)}/>
                    </Dialog>
                </Navbar>
            </header>
        )
    }
}

const mapStateToProps = (state: RootState): IMappedProps => {
    return{ email: state.user.email, name: state.user.name, token: state.user.token}
};

const mapDispatchToProps = (dispatch: Dispatch<UserActionTypes & SummaryActionTypes>) =>
  bindActionCreators(
    {
      clearState,
      clearUserState
    },
    dispatch
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderComponent)